<template>
  <div>
    <img v-if="this.which == 0" src="@/assets/images/marketAnalyze/fashion.jpg">
    <img v-else-if="this.which == 1" src="@/assets/images/marketAnalyze/trendiencer.jpg">
    <img v-else-if="this.which == 5" src="@/assets/images/marketAnalyze/grocery.jpg">
    <div v-else class="default">
      해당 카테고리 분석은 준비중입니다.
    </div>
  </div>
</template>

<script>
export default {
  name : 'MarketAnalyze',
  props:{
    which : String,
  },
  mounted(){
    if(this.which != '0' && this.which != '1' && this.which != '5'){
      this.$store.commit('alert', '해당 카테고리 분석은 준비중입니다.')
    }
  },
}
</script>

<style scoped>
img{
  width: 100%;
  margin-top: 70px;
}
.default{
  margin-top: 70px;
  padding: 10px;
  text-align: center;
}
</style>